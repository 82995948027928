@import '../../GlobalStyles.scss';
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}
.background-video-content {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 30%;
    padding: 0 20px;
    h1 {
        color: $black;
        font-size: 60px;
        line-height: 74px;
        font-weight: 300;
        margin: 0 auto 15px;
        background-color: rgba(255, 255, 255, 0.5);
        max-width: 720px;
    }
    h2 {
        color: $white;
        font-size: 30px;
        line-height: 40px;
        font-weight: 300;
        margin: 0 auto;
        max-width: 720px;
    }
}
.homepage-one-item {
    background-color: $primarycolor;
    padding: 80px 0;
    .item {
        display: flex;
        padding: 5px 15px;
        justify-content: center;
        align-items: center;
        .box-description {
            flex: 1;
            margin-right: 30px;
            padding: 20px;
            background-color: $green;
            border-radius: 25px 0;
            text-align: center;
            .description {
                font-size: 25px;
                line-height: 35px;
                font-weight: 300;
                color: $white;             
            }
            .article-link {
                color: $white;
                text-decoration: none;
                font-size: 18px;
                line-height: 26px;
                font-weight: 300;
                &::after {
                    content: "";
                    border: solid $white;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 3px;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    margin-left: 5px;
                }
            }
        }
        .box {
            text-align: center;
            flex: 1;
            .title {
                font-size: 20px;
                line-height: 28px;
                font-weight: 300;
            }
            .preview-image {
                text-align: center;
                img {
                    width: 100%;
                }
                .player-wrapper {
                    position: relative;
                    padding-top: 56.25%
                }
                .react-player {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100% !important;
                }
            }
        }
    }
    &.smaller-space {
        margin-top: 80px;
    }
}
.homepage-two-items {
    padding: 80px 0;
    .container {
        display: flex;
        .item {
            padding: 5px 15px;
            flex: 1;
            .box {
                text-align: center;
                .article-link {
                    text-decoration: none;
                    .title {
                        font-size: 20px;
                        line-height: 28px;
                        padding: 20px;
                        background-color: $secondarycolor;
                        color: $black;
                        border-radius: 25px 0;
                        font-weight: 300;
                        text-align: center;
                        margin-top: 15px;
                        &::after {
                            content: "";
                            border: solid $black;
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 4px;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            margin-left: 5px;
                        }
                    }
                }
                .preview-image {
                    text-align: center;
                    img {
                        width: 100%;
                    }
                    .player-wrapper {
                        position: relative;
                        padding-top: 56.25%
                    }
                    .react-player {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100% !important;
                    }
                }
            }
        }
    }
}
.homepage-three-items {
    padding: 80px 0;
    .container {
        display: flex;
        .item {
            padding: 5px 15px;
            .box {
                text-align: center;
                position: relative;
                .article-link {
                    text-decoration: none;
                    .title {
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 300;
                        &::after {
                            content: "";
                            border: solid $black;
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 3px;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            margin-left: 5px;
                            margin-bottom: 2px;
                        }
                    }
                }
                .preview-image {
                    text-align: center;
                    img {
                        width: 100%;
                    }
                    .player-wrapper {
                        position: relative;
                        padding-top: 56.25%
                    }
                    .react-player {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100% !important;
                    }
                }
                .hidden-content {
                    display: flex;
                    position: absolute;
                    width: 80%;
                    bottom: 6px;
                    right: 0px;
                    height: auto;
                    background-color: $secondarycolor;
                    color: $black;
                    flex-direction: column;
                    justify-content: center;
                    padding: 15px;
                    opacity: 0;
                    transition: opacity .3s ease-in-out;
                    &.hidden-content-video {
                        bottom: 0;
                    }
                }
                &:hover {
                    .hidden-content {
                        transition: 2s;
                        opacity: 1;
                        transition: opacity .3s ease-in-out;
                    }
                }
            }
        }
    }
    .small-boxes {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .big-box {
        flex: 2;
    }
}
.homepage-single-description {
    background-color: $primarycolor;
    padding: 80px 0;
    .single-description {
        padding: 5px 15px;
        &>* {
            color: $white;
        }
    }
    &.smaller-space {
        margin-top: 80px;
    }
}

@media (max-width: 1199px) {
    .homepage-three-items {
        .container {
            flex-direction: column;
            .small-boxes {
                flex-direction: row;
                .item {
                    flex: 1;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .background-video-content {
        top: 35%;
    }
    .homepage-single-description {
        padding: 40px 0;
        &.smaller-space {
            margin-top: 40px;
        }
    }
    .homepage-one-item {
        padding: 40px 0;
        .item {
            flex-direction: column;
            .box {
                order: 0;
                width: 100%;
                margin-bottom: 10px;
            }
            .box-description {
                order: 1;
                margin-right: 0;
                width: 100%;
            }
        }
        &.smaller-space {
            margin-top: 40px;
        }
    }
    .homepage-two-items {
        padding: 40px 0;
        .container {
            display: block;
            .item {
                .box {
                    .title {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .homepage-three-items {
        display: block;
        padding: 40px 0;
        .small-boxes {
            display: block;
        }
        .container {
            .item {
                .box {
                    .hidden-content {
                        display: block;
                        position: relative;
                        width: 100%;
                        bottom: 6px;
                        right: unset;
                        height: auto;
                        opacity: 1;
                        padding: 20px;
                        border-radius: 25px 0;
                        margin-top: 15px;
                        &.hidden-content-video {
                            bottom: 6px;
                        }
                    }
                }
            }
        }
    }
}
@font-face {
  font-family: "brandon";
  src: url("./fonts/Brandon-light.eot");
  src: local("☺"), url("./fonts/Brandon-light.ttf") format("truetype"),
    url("./fonts/Brandon-light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  background-color: #f9f0f9;
  & * {
    font-family: "brandon";
    color: #000;
    font-size: 16px;
    line-height: 24px;
  }
}
* {
  box-sizing: border-box;
}
.react-player__preview:focus {
  outline: none !important;
}

@import '../../GlobalStyles.scss';
.main-part {
    position: absolute;
    top: 150px;
    width: 100%;
    .main-part-inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 15px 60px;
    }
}
.articles-grid {
    display: flex;
    flex-wrap: wrap;
    .article-item {
        flex: 1 0 31.3%;
        margin: 0 1% 15px;
        .article-item-content {
            background-color: $secondarycolor;
            padding: 15px;
            color: #000;
            border-radius: 25px 0;
            text-align: center;
            &>a {
                text-decoration: none;
                h2 {
                    margin: 0;
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .articles-grid {
        display: block;
    }
}
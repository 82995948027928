@import '../../GlobalStyles.scss';
.parallax-video {
    &>div:not(.background-video-content) {
        height: 100vh !important;
    }
    div[data-vimeo-initialized="true"] {
        position: fixed;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        z-index: -1;
        pointer-events: none;
        overflow: hidden;
        iframe {
            width: 100vw;
            height: 56.25vw !important; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            min-height: 100vh !important;
            min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    video {
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -100;
        transform: translateX(-50%) translateY(-50%);
        background-size: cover;
        transition: 1s opacity;
        -webkit-transition-position: fixed;
        position: fixed;
    }
    &.hide-background-video {
        display: none;
    }
}
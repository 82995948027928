@import "../../GlobalStyles.scss";
#main-navigation {
  position: fixed;
  z-index: 1;
  width: 100%;
  & > .main-navigation-inner {
    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      left: 0;
      top: 0;
      width: 100% !important;
      .bm-menu {
        background: $primarycolor;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
        .bm-item-list {
          color: $white;
          padding: 0.8em;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          .bm-item {
            display: flex !important;
            flex-direction: column;
            margin-bottom: 10px;
            text-align: center;
            text-decoration: none;
            transition: color 0.2s;
            font-size: 40px;
            line-height: 54px;
            flex: 1;
            padding-right: 20px;
            &.menu-items:focus {
              outline: none;
            }
            .menu-item {
              color: $white;
              text-decoration: none;
              font-size: 40px;
              line-height: 54px;
              &:hover {
                color: $white;
                opacity: 0.6;
              }
              &:focus {
                outline: none;
              }
            }
            .menu-languages {
              margin-top: 15px;
              a {
                text-decoration: none;
                font-size: 25px;
                line-height: 32px;
                &:first-child {
                  margin-right: 10px;
                  padding-right: 10px;
                  border-right: 2px solid $white;
                }
                &:hover {
                  span {
                    opacity: 0.6;
                  }
                }
                span {
                  color: $white;
                  font-size: 25px;
                  line-height: 32px;
                }
              }
            }
          }
        }
      }
      .bm-cross-button {
        height: 40px !important;
        width: 30px !important;
        left: 15px;
        top: 20px !important;
        cursor: pointer;
        &:hover {
          opacity: 0.6;
        }
        button {
          &:focus {
            outline: none;
          }
        }
        & > span {
          .bm-cross {
            height: 30px !important;
            width: 5px !important;
            background: $white;
          }
          .bm-cross:nth-child(1) {
            background: $red;
          }
          .bm-cross:nth-child(2) {
            background: $orange;
          }
        }
      }
    }
  }
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 15px;
    top: 20px;
    cursor: pointer;
    button {
      &:focus {
        outline: none;
      }
    }
    & > span {
      .bm-burger-bars:nth-child(1) {
        background: $red;
      }
      .bm-burger-bars:nth-child(2) {
        background: $orange;
      }
      .bm-burger-bars:nth-child(3) {
        background: $yellow;
      }
    }
    .bm-burger-bars-hover {
      background: $white;
    }
  }
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
  }
  .site-logo {
    right: 15px;
    top: 20px;
    position: fixed;
    max-width: 150px;
  }
}

@media (max-width: 767px) {
  #main-navigation {
    & > .main-navigation-inner {
      .bm-menu-wrap {
        .bm-menu {
          .bm-item-list {
            display: block;
          }
        }
      }
    }
  }
}

@import "../../GlobalStyles.scss";
.main-part {
  .main-part-inner {
    .article-header {
      background-color: $primarycolor;
      margin: 0 auto;
      padding: 0 15px;
      .article-header-content {
        .image-container {
          & > div {
            position: relative;
            background-attachment: fixed;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 400px;
          }
        }
        padding: 80px 22% 50px 8%;
        h1 {
          margin-bottom: 0;
          font-size: 60px;
          line-height: 74px;
          color: $white;
          font-weight: 300;
          word-break: break-word;
        }
      }
    }
    .article-content {
      background-color: $postsbackground;
      .article-content-inner {
        max-width: 1100px;
        margin: 0 auto;
        padding: 80px 15px;
        .share-section {
          display: flex;
          margin-top: 20px;
          display: none;
          h4 {
            margin: 4px 10px 0 0;
          }
          .facebook-share-btn {
            height: 32px;
            &:focus {
              outline: none;
            }
          }
        }
        .language-switcher {
          display: flex;
        }
      }
    }
  }
}
h1.not-found {
  font-size: 50px;
  line-height: 64px;
  color: $black;
  position: absolute;
  top: 30%;
  width: 100%;
  text-align: center;
  padding: 0 15px;
  background-color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 767px) {
  .main-part {
    .main-part-inner {
      .article-header {
        .article-header-content {
          padding: 80px 0 50px 0;
          .image-container {
            & > div {
              height: 300px;
            }
          }
        }
      }
    }
  }
}

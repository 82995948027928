@import '../../GlobalStyles.scss';
.search-bar {
    flex: 2;
    padding-left: 20px;
    &::before {
        content: '';
        width: 2px;
        background-color: $white;
        height: 100%;
        position: absolute;
        bottom: 0;
        margin-left: -20px;
    }
    input {
        width: 100%;
        border: 0;
        height: 54px;
        padding: 0 15px;
        background-color: transparent;
        border-bottom: 2px solid $white;
        color: $white;
        font-size: 40px;
        line-height: 54px;
        margin-bottom: 20px;
        &:focus {
            outline: none;
        }
        &::-webkit-input-placeholder {
            color: $white;
        }
        &::-moz-placeholder {
          color: $white;
        }
        &:-ms-input-placeholder {
          color: $white;
        }
        &:-moz-placeholder {
          color: $white;
        }
    }
    .search-results {
        display: flex;
        flex-wrap: wrap;
        .item {
            flex: 1 1 31.33%;
            margin: 1%;
            a {
                text-decoration: none;
                img {
                    width: 100%;
                }
                .article-title {
                    font-size: 20px;
                    line-height: 28px;
                    padding: 5px 15px;
                    background-color: $secondarycolor;
                    color: $black;
                    border-radius: 25px 0;
                    font-weight: 300;
                    text-align: center;
                    margin: 0;
                }
            }
        }
        .search-heading {
            font-size: 20px;
            flex: 1 100%;
            margin: 20px 0 0 1%;
            color: $white;
        }
    }
}

@media (max-width: 767px) {
    .search-bar {
        &::before {
            display: none;
        }
        .search-results {
            display: block;
            .item {
                a {
                    .article-title {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}